(function ($) {
    "use strict";

    $(window).on('load', function () {
        preLoader();
        masonryInit();
    });

    $(window).on('scroll', function () {
        var scrollToTop = $('.scroll-to-top'),
            scroll = $(window).scrollTop();
        if (scroll >= 500) scrollToTop.addClass('show');
        else scrollToTop.removeClass('show');
    });

    $(document).ready(function () {
        smoothScroll();
        searchToggle();
        bannerCarousel();
        serviceCarousel();
        popupInit();
        membersCarousel();
        testimonialsCarousel();
        blogsCarousel();
    });
    
    if ($(window).width() < 992) {
        $('.navbar-1 .dropdown-toggle, .navbar-2 .dropdown-toggle').on('click', function(e) {
            $(this).siblings('.dropdown-menu').animate({
                height: "toggle"
            }, 300);
        });
    }
    $('.navbar-3 .dropdown-toggle').on('click', function(e) {
        $(this).siblings('.dropdown-menu').animate({
            height: "toggle"
        }, 300);
    });

    // PreLoader Init
    function preLoader() {
        $('body').addClass('loaded');
        $('.preloader-wrapper').addClass('loaded');
    };

    // niceSelect Init
    $('select').niceSelect();

    // searchToggle Init
    function searchToggle() {
        $('.search-toggle').on('click', function() {
            $('.search-wrapper').addClass('showSearch');
        });
        $('.search-close-wrap, .search-close-btn').on('click', function() {
            $('.search-wrapper').removeClass('showSearch');
        });
    };

    // scroll to top init
    $('.scroll-to-top').each(function(){
        $(this).click(function(){ 
            $('html,body').animate({ scrollTop: 0 }, '500');
            return false; 
        });
    });

    // Smooth-Scroll init
    function smoothScroll() {
        var scrollLink = $('#spy-nav a, .smooth-scroll');
        scrollLink.on("click", function () {
            if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
                var target = $(this.hash);
                target = target.length ? target : $("[name=" + this.hash.slice(1) + "]");
                if (target.length) {
                    $("html, body").animate({
                        scrollTop: target.offset().top
                    }, 700, "easeInOutExpo");
                    return false;
                }
            }
        });
    };


    // banner carousel
    function bannerCarousel() {
        $('.banner-carousel').owlCarousel({
            loop: false,
            margin: 0,
            nav: true,
            dots: false,
            items: 1,
            navText: ['<i class="fas fa-chevron-left"></i>', '<i class="fas fa-chevron-right"></i>']
        });
    }

    // service carousel
    function serviceCarousel() {
        $('.service-carousel').owlCarousel({
            loop: false,
            margin: 30,
            nav: true,
            dots: false,
            responsive : {
                0 : {
                    items: 1
                },
                768 : {
                    items: 2
                },
                991 : {
                    items: 3
                },
                1199 : {
                    items: 4
                }
            },
            navText: ['<i class="fas fa-chevron-left"></i>', '<i class="fas fa-chevron-right"></i>']
        });
    }

    // members carousel
    function membersCarousel() {
        $('.members-carousel').owlCarousel({
            loop: false,
            margin: 30,
            nav: true,
            dots: false,
            responsive : {
                0 : {
                    items: 1
                },
                768 : {
                    items: 2
                },
                991 : {
                    items: 3
                },
                1199 : {
                    items: 4
                }
            },
            navText: ['<i class="fas fa-chevron-left"></i>', '<i class="fas fa-chevron-right"></i>']
        });
    }

    // testimonials carousel
    function testimonialsCarousel() {
        $('.testimonials-carousel').owlCarousel({
            loop: false,
            margin: 30,
            nav: true,
            dots: false,
            responsive : {
                0 : {
                    items: 1
                },
                768 : {
                    items: 2
                },
                991 : {
                    items: 3
                },
                1199 : {
                    items: 4
                }
            },
            navText: ['<i class="fas fa-chevron-left"></i>', '<i class="fas fa-chevron-right"></i>']
        });
    }

    // blogs carousel
    function blogsCarousel() {
        $('.blogs-carousel').owlCarousel({
            loop: false,
            margin: 30,
            nav: true,
            dots: false,
            responsive : {
                0 : {
                    items: 1
                },
                991 : {
                    items: 2
                }
            },
            navText: ['<i class="fas fa-chevron-left"></i>', '<i class="fas fa-chevron-right"></i>']
        });
    }

    // masonry init
    function masonryInit() {
        $('.masonry').isotope();
    }

    // popup init
    function popupInit() {
        $(".popup-gallery").magnificPopup({
            delegate: "a.pop-img",
            type: "image",
            closeOnContentClick: !1,
            closeBtnInside: !1,
            mainClass: "mfp-with-zoom mfp-img-mobile",
            fixedContentPos: false,
            image: {
                verticalFit: !0
            },
            gallery: {
                enabled: !0
            },
            zoom: {
                enabled: !0,
                duration: 300,
                opener: function (a) {
                    return a.find("img");
                }
            }
        });
        if ($('.popup-youtube').length > 0) {
            $('.popup-youtube').magnificPopup({
                type: 'iframe',
                mainClass: 'mfp-fade',
                removalDelay: 160,
                preloader: false,
                fixedContentPos: false
            });
        };
    }

})(jQuery);